import logo from './jnrdev-logo.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} alt="Logo" />
        <i class="logo-desc"></i>
        <p>
          JNRDev Web & Mobile App Development
        </p>
        <p>
          Contact -&nbsp;
          <a
            className="App-link"
            href="mailto:jnrdevappdev@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            jnrdevappdev@gmail.com
          </a>
        </p>

      </header>
    </div>
  );
}

export default App;
